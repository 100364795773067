.regContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Arial;
    height: 98vh;
}

.regFormContainer {
    -webkit-box-shadow: 0px 0px 15px 3px rgba(0,0,0,0.85);
    -moz-box-shadow: 0px 0px 15px 3px rgba(0,0,0,0.85);
    box-shadow: 0px 0px 15px 3px rgba(0,0,0,0.85);
    border: 1px solid rgb(0, 0, 0);
    width: 500px;
    height: 600px;
    overflow:hidden;
    border: 1px solid rgb(0, 0, 0);
}

.regFormTop {
    height: 25%;
    border-bottom: 1px solid;
}

.regFormTitle {
    display: flex;
    justify-content: center;
    align-items: center;
}

.regFormNote {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 30px;
    padding-left: 50px;
}

.regFormMid {
    height: 60%;
}

.row {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 20px;
    padding-top: 20px;
}

.refinputbox {
    width: 40%;
}

.rowForm {
    display: flex;
    align-items: left;
    justify-content: left;
    column-gap: 20px;
    padding-top: 20px;
}

.refforminputbox {
    padding-left: 50px
}

.regFormbottom {
    height: 15%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-top: 1px solid;
    padding-bottom: 10px;
}

.regSubmit {
    padding: 10px;
}
