.loginContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 98vh;
    font-family: Arial;
}

.login {
    -webkit-box-shadow: 0px 0px 27px -2px rgba(0,0,0,1);
    -moz-box-shadow: 0px 0px 27px -2px rgba(0,0,0,1);
    box-shadow: 0px 0px 27px -2px rgba(0,0,0,1);
    padding-left: 75px;
    padding-right: 75px;
    padding-top: 50px;
    padding-bottom: 25px;
    background-color: rgb(255, 255, 255);
}

.title {
    width: 100%;
    margin: auto;
    text-align: center;
}

.username {
    display: flex;
    justify-content: center;
    padding: 2px;
    margin: 5px;

}

.password {
    display: flex;
    justify-content: center;
    padding: 2px;
    margin: 5px;

}

.submit{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.loginButton {
    margin-top: 10px;
    padding: 5px;
}