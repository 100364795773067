.trackerTitle{
    text-align: center;
    font-family:Arial, Helvetica, sans-serif;
}

.trackerLogOut {
    display: flex;
    justify-content: right;
    flex-direction: row;
    font-family:Arial, Helvetica, sans-serif;
    padding-right: 50px;
}

.trackingLogOutButton {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
}

.trackerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.trackerMid {
    text-align: center;
    width: 75%;
}
